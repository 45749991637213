// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Jquery
import jquery from 'jquery';
window.$ = window.jquery = jquery;
window.jQuery = window.jquery = jquery;

// Common Lib
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
global.toastr = require('toastr');
global.bootbox = require('bootbox');
require('moment');
require('inputmask');
require('select2');
require('bootstrap-datepicker');
require('bootstrap-timepicker');
require('bootstrap-slider');

// Theme + Libraries
require('admin-lte');
require.context('admin-lte/dist/img', true);
require("packs/vendor/flatpickr");
require("packs/vendor/timepicker");
require("packs/vendor/jquery-ui.js");
// require("packs/vendor/ckeditor.js");
require("packs/vendor/jquery-smartphoto.min.js");
require("packs/vendor/lazyload.min.js");

// AngularJs
global.angular = require('angular');
require('angular-route');
require("packs/base/init");
require("packs/base/angular_app");
require("packs/base/angular_utils");

// AngularJs Controllers
global.app = angular.module('app');
require("packs/pages/dashboard_ctrl");
